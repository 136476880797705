import { request } from 'graphql-request';
import query from '~/graphQl/query.gql';

const BASE_URI = `${process.env.BASE_URI}`;
const PORT = `${process.env.SERVER_PORT}`;

const API_URI = `${BASE_URI}:${PORT}/graphql`;

const statistic = {
  countUsersLast24Hours: document.getElementById('countUsersLast24Hours'),
  totalEarned: document.getElementById('totalEarned'),
  totalParticipants: document.getElementById('totalParticipants'),
};

const formatNumberWithSpaces = number => {
  // Используем локаль, которая разделяет группы чисел пробелами
  return parseInt(number, 10).toLocaleString('ru-RU');
};

const getUsersStatisticQuery = async () => {
  try {
    const res = await request(API_URI, query);
    if (res) {
      localStorage.setItem(
        'countUsersLast24Hours',
        formatNumberWithSpaces(res.countUsersLast24Hours)
      );
      localStorage.setItem(
        'totalParticipants',
        formatNumberWithSpaces(res.totalParticipants)
      );
      localStorage.setItem(
        'totalEarned',
        `$${formatNumberWithSpaces(res.totalEarned)}`
      );
    }
  } catch (error) {
    console.error(error);
  }

  statistic.countUsersLast24Hours.textContent =
    localStorage.getItem('countUsersLast24Hours') || 0;
  statistic.totalParticipants.textContent =
    localStorage.getItem('totalParticipants') || 0;
  statistic.totalEarned.textContent =
    localStorage.getItem('totalEarned') || '$0';
};
getUsersStatisticQuery();
